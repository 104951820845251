<template>
    <section>
        <span id="page-progressbarW"></span>
        <template>
            <span id="line-progress"></span>
            <span id="page-progressbarH"></span>
            <span id="line-progress2"></span>
            <span id="page-progressbarH2"></span>
        </template>
    </section>
</template>
<script>
export default {
    name:"scroll-progress",
    props:{
        key:{
            type:String,
            default:"key"
        }
    },
    mounted(){
            window.onscroll = () => {
            let currentScroll =  document.documentElement.scrollTop;
            let contentHeight = document.documentElement.scrollHeight;
            let windowHeight = document.documentElement.clientHeight;
            let remainingScroll = contentHeight - windowHeight;
            let scrolled = (currentScroll / remainingScroll) * 100;
        
            let progressbarW = document.getElementById("page-progressbarW");
            let progressbarH = document.getElementById("page-progressbarH");
            let progressbarH2 = document.getElementById("page-progressbarH2");
            // let scrollVal = document.getElementById("scrolledVal");
        
            // progressbarW.style.width = `${scrolled}%`;
            progressbarW.style.left = `${scrolled}%`;
            // progressbarH.style.height = `${scrolled}%`;
            progressbarH.style.top = `${scrolled}%`;
            progressbarH2.style.bottom = `${scrolled}%`;
            // scrollVal.innerHTML = `${Math.round(scrolled)}%`;
        }
    }
}
</script>
<style scoped>
/* #page-progressbarW{
  width:5%;
  height:5px;
  background-color:#f93a00;
  position:fixed;
  top:0;
  left:0;
} */
/* #page-progressbarW::after{
    content:'';
    background: brown;
    height: 20rem;
    width: 20rem;
} */
#line-progress{
    width:2px;
    height:100%;
    background-color:#c8c8c8;
    position:fixed;
    z-index: 33;
    top:6px;
    left:1.1%;
}
#page-progressbarH{
  width:5px;
  height:10%;
  background-color:#f93a00;
  z-index: 34;
  position:fixed;
  top:6px;
  left:1%;
}
#line-progress2{
    width:2px;
    height:100%;
    background-color:#c8c8c8;
    position:absolute;
    bottom:6px;
    right:2.1%;
}
#page-progressbarH2{
  width:5px;
  height:1%;
  background-color:#f93a00;
  position:absolute;
  bottom:6px;
  right:2%;
}
#scrolledVal{
  position:fixed;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
}
</style>